.products{
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    .productRow{
        .heart{
        //     .wishlistBoxOpen{
        //         animation-name: wishlistBoxOpen;
        //         animation-duration: 1s;
        //     }
        // }
        // @keyframes wishlistBoxOpen {
        //     from{
        //         height: 0;
        //         padding: 0;
        //         border: 0;
        //         overflow-y: hidden;
        //         color: transparent;
        //     }
        //     to{
        //         height: 274px;
        //         padding: 11px;
        //         border: 2px solid gray;
        //         overflow-y: scroll;
        //         color: black;
        //     }
        // }
        // }
    }

    .product{
        height: 100%;
        width: 225px;
        display: flex;
        border-bottom: 2px solid gray;
        border-radius: 3px;
        justify-content: space-evenly;
        align-items: center;
        padding: 3rem;

        .link{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .productText{
            display: flex;
            justify-content: space-evenly;
            align-items: flex-end;
        .description{
            height: 65px;
            width: 100px;
            padding: 3px;
            font-weight: 500;
            font-family: 'Roboto', sans-serif;
        }
        }
    }
}
}