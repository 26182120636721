.home{
  background-color: rgb(245, 249, 255);
  width: 100%;
  min-height: calc(100vh - 96px);
}
.intro {
  background-image: url(../../assets/intro.png);
  width: 100%;
  height: 415px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.categories {
  overflow-x: scroll;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  height: 85px;
  gap: 10px;
  background-color:#ffbf47;
  

  button {
    border: 1px solid gray;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    span {
      font-size: 18px;
    }

    div {
      background-image: url(../../../public/logo192.png);
      background-size: cover;
      width: 40px;
      height: 40px;
    }
  }
}

.productContent {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 3rem;
  gap: 2rem;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;

  .product {
    width: 170px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;

    .productImage {
      width: 100%;
      height: 150px;
      object-fit: contain;
      border-radius: 16px;
    }

    .productRow {
      display: flex;
      justify-content: space-between;
    }
    .description {
      display: flex;
      text-align: center;
      width: 100%;
      height: 40px;
      font-weight: 350;
      font-family:'Trebuchet MS';
    }
  }
}

.ReactModal__Content {
  inset: 0px !important;
  max-height: 460px;
  max-width: 800px;
  margin: auto;
  border-radius: 25px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    background-color: #ffa500;
    border-radius: 4px;
    border: 2px solid black;
    height: 35px;
    width: 35px;
    font-size: 18px;
    cursor: pointer;
  }
}

.activeCategory {
  border: 3px solid orange !important;
}

.swiper-wrapper{
  margin-top: 12px;
}
.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  border-radius: 100%;
  border: 2px solid black;
  height: 40px !important;
  width: 40px !important;
}
.swiper-button-prev:after,
.swiper-button-next:after{
  font-size: 20px !important;
  font-weight: 800 !important;
  color: black;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
}
.pagination{
  display: flex;
  justify-content: center !important;
  .active > .page-link{
    background-color: #ffea72 !important;
  }
  .page-link{
    color: rgb(73, 32, 0) !important;
    font-weight: 600 !important;
    border: 2px solid rgb(255, 180, 42)!important;
  }
}
@media screen and (max-width:768px){
  .ReactModal__Content{
    height: 200px;
    width: 320px;
  }
}